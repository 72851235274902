import {
  computed,
  ComputedRef,
  nextTick,
  reactive,
  Ref,
  ref,
  watch
} from "vue";
import { useBaseAppLayout } from "@/hooks/layout/useBaseAppLayout";
import { getFullDate } from "@/utills/dateConverter";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sorting, useBaseTable } from "@/hooks/tables/useTable";
import { sortOrders } from "@/enums/main/sortOrders";
import { badgeSizes, badgeTypes } from "@/components/ui/AppBadge/interfaces";
import moment from "moment";
import {
  esimProfile,
  resellerProfile,
  resellerCountData,
  esimType,
  providerType,
  IEsimServerFull,
  Profile
} from "@/api/services/profiles/profiles.types";
import { api } from "@/api/Api";
import { useStore } from "vuex";

export default function useEsimProfiles() {
  interface IActiveServer extends IEsimServerFull {
    updatedDate: string;
    uploadedDate: string;
  }

  const store = useStore();

  const START_YEAR = 2020;
  const CURRENT_YEAR = new Date(Date.now()).getFullYear();

  const initialViewConfiguration = { page: 1, perPage: 10 };
  const loading = ref(true);
  const buttonLoading = ref(false);
  const uploadLoading = ref(false);
  const modalLoading = ref(false);
  const importModalOpened = ref(false);
  const installationModalOpened = ref(false);
  const installationTitle = ref("");

  const uploadProgress = ref(0);
  const uploadSuccess: Ref<boolean | null> = ref(null);
  const uploadErrorMessage = ref("");

  const activeProviderIdx = ref(0);
  const activeTypeIdx = ref(-1);
  const activeServerIdx = ref(-1);

  const providers: Ref<providerType[]> = ref([]);
  const slots: Ref<esimType[]> = ref([]);
  const servers: Ref<IEsimServerFull[]> = ref([]);
  const profiles: Ref<Profile[]> = ref([]);
  const totalProfiles = ref(0);
  const resellerCountData = ref({} as resellerCountData);
  const activeServer: ComputedRef<IActiveServer> = computed(() => {
    const server = servers.value[activeServerIdx.value] || {};
    return {
      ...server,
      updatedDate: server ? getFullDate(new Date(server.updated)) : "",
      uploadedDate: server ? getFullDate(new Date(server.uploaded)) : ""
    };
  });

  const yearsList = Array.from(
    {
      length: CURRENT_YEAR - START_YEAR + 1
    },
    (_, idx) => START_YEAR + idx
  );

  const qr = reactive({
    image: "",
    iccid: "",
    address: "",
    code: ""
  });

  fetchSlots();

  const providersTitles = computed(() => {
    return providers.value.map(({ name }) => name);
  });

  const slotsTitles = computed(() => {
    return slots.value.map(({ title }) => title);
  });

  const serversTitles = computed(() => {
    return servers.value.map(({ title }) => title);
  });

  const appRefs = useBaseAppLayout({
    filterOptions: ["All", ...yearsList.map(year => year.toString())],
    initialSelectedFilterOptionIdx: 0
  });

  const selectedYear = computed(() => {
    return appRefs.selectedFilterOption.value?.name || "All";
  });

  const isResellerProvider = computed(
    () => providers.value[activeProviderIdx.value]?.name === "Reseller"
  );

  const changeTab = (idx: number) => {
    activeProviderIdx.value = idx;
  };

  function hideImportModal() {
    if (uploadLoading.value) return;

    importModalOpened.value = false;
    uploadProgress.value = 0;
    uploadLoading.value = false;
    uploadErrorMessage.value = "";
    uploadSuccess.value = null;
  }

  async function importFile(table: File) {
    uploadProgress.value = 0;
    uploadSuccess.value = null;
    uploadErrorMessage.value = "";

    const serverId = servers.value[activeServerIdx.value]?.serverId;
    if (serverId) {
      uploadLoading.value = true;

      // "data" not string when success
      const { data } = await api.importProfile(
        table,
        serverId,
        (progress: number) => {
          if (uploadProgress.value === 0) {
            if (progress === 100) {
              const interval = setInterval(() => {
                uploadProgress.value += 0.6;
                if (uploadProgress.value >= 90) {
                  clearInterval(interval);
                }
              }, 15);

              return;
            }

            uploadProgress.value = progress;
            return;
          }

          progress < 99 && (uploadProgress.value = progress);
        }
      );

      uploadLoading.value = false;
      uploadProgress.value = 100;

      const isData = typeof data === "string";
      if (isData) {
        const msg = (data as unknown) as string;
        uploadErrorMessage.value = msg || "";
        uploadSuccess.value = false;
        return;
      }

      uploadSuccess.value = true;
      await fetchProfiles();
    }
  }

  watch(
    () => activeTypeIdx.value,
    async () => {
      const id = slots.value[activeTypeIdx.value]?.id;
      id &&
        !isResellerProvider.value &&
        (await fetchServers(id, selectedYear.value));
    },
    { deep: true }
  );

  watch(
    () => servers.value,
    () => {
      activeServerIdx.value = 0;
    },
    { deep: true }
  );

  watch(
    () => importModalOpened.value,
    opened => {
      if (!opened) {
        uploadProgress.value = 0;
      }
    }
  );

  const sortConfiguration: sorting = {
    byStatus: {
      keyNameInModel: "numericActive",
      order: sortOrders.asc,
      keyName: "active"
    },

    byDateUsed: {
      keyName: "date",
      order: sortOrders.turnOff,
      keyNameInModel: "dateUsed"
    }
  };

  const tableRefs = useBaseTable({
    initialViewConfiguration: { page: 1, perPage: 10 },
    model: computed(() =>
      profiles.value.map(profile => {
        return { ...profile, numericActive: profile.active ? 1 : 0 };
      })
    ),
    initialSortOptions: { ...sortConfiguration },
    generateRows(
      profilesList: esimProfile[] | resellerProfile[]
    ): ITableBodyRow[] {
      if (!profilesList) {
        return [];
      }

      return profilesList.map(
        (profile: esimProfile | resellerProfile): ITableBodyRow => {
          if ("listId" in profile) {
            const {
              active,
              dateUsed,
              device,
              version,
              userId,
              email,
              listId
            } = profile;
            return {
              onClick: () => {
                openQrModal("id", listId);
              },
              actions: [
                {
                  text: "Show QR",
                  onClick: () => {
                    openQrModal("id", listId);
                  }
                }
              ],
              cells: [
                { label: userId || "-" },
                { label: email || "-" },
                {
                  badge: {
                    label: active ? "Free" : "Used",
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,
                    size: badgeSizes.md
                  }
                },
                {
                  infoWithImage: {
                    title: dateUsed
                      ? moment(dateUsed).format("D MMM YYYY, DDD")
                      : "-",
                    description: dateUsed
                      ? moment(dateUsed).format("h:mm a Z")
                      : "-"
                  }
                },
                {
                  infoWithImage: {
                    title: device || "-",
                    description: version || "-"
                  }
                }
              ]
            };
          } else {
            const {
              sku,
              assigned_date,
              email_tag,
              product_code,
              availability
            } = profile;
            let { active } = profile;
            active = active && availability === "Free";
            return {
              onClick: () => {
                openQrModal("sku", sku);
              },
              actions: [
                {
                  text: "Show QR",
                  onClick: () => {
                    openQrModal("sku", sku);
                  }
                }
              ],
              cells: [
                { label: product_code || "-" },
                { label: email_tag || "-" },
                {
                  badge: {
                    label: active ? "Free" : "Used",
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,
                    size: badgeSizes.md
                  }
                },
                {
                  infoWithImage: {
                    title: assigned_date
                      ? moment(assigned_date).format("D MMM YYYY, DDD")
                      : "-",
                    description: assigned_date
                      ? moment(assigned_date).format("h:mm a Z")
                      : "-"
                  }
                },
                {
                  infoWithImage: {
                    title: "-",
                    description: "-"
                  }
                }
              ]
            };
          }
        }
      );
    }
  });

  const rows = computed(() => tableRefs.rows.value);

  const cells: ComputedRef<ITableHeadCell[]> = computed(() => {
    if (!isResellerProvider.value) {
      return [
        {
          id: "user-id",
          label: "User ID",
          size: "md"
        },
        {
          id: "email",
          label: "Email",
          size: "md"
        },
        {
          id: "status",
          size: "md",
          label: "Status",
          sort: {
            keyName: tableRefs.currentSort.byStatus.keyName,
            order: tableRefs.currentSort.byStatus.order
          }
        },
        {
          id: "Date used",
          label: "Date used",
          size: "lg",
          sort: {
            keyName: tableRefs.currentSort.byDateUsed.keyName,
            order: tableRefs.currentSort.byDateUsed.order
          }
        },
        {
          id: "Device",
          label: "Device",
          size: "md"
        }
      ];
    } else {
      return [
        {
          id: "product-code",
          label: "Product code",
          size: "md"
        },
        {
          id: "email",
          label: "Email",
          size: "md"
        },
        {
          id: "status",
          size: "md",
          label: "Status",
          sort: {
            keyName: tableRefs.currentSort.byStatus.keyName,
            order: tableRefs.currentSort.byStatus.order
          }
        },
        {
          id: "assigned-date",
          label: "Assigned date",
          size: "lg",
          sort: {
            keyName: tableRefs.currentSort.byDateUsed.keyName,
            order: tableRefs.currentSort.byDateUsed.order
          }
        },
        {
          id: "device",
          label: "Device",
          size: "md"
        }
      ];
    }
  });

  watch(
    [
      () => activeServerIdx.value,
      () => activeTypeIdx.value,
      () => appRefs.selectedFilterOption.value
    ],
    () => {
      tableRefs.changeViewConfiguration({
        page: 1,
        perPage: tableRefs.viewConfiguration.perPage
      });
    },
    { deep: true }
  );

  watch(
    () => activeProviderIdx.value,
    () => {
      if (!isResellerProvider.value && !slots.value.length) {
        fetchSlots();
      } else if (!isResellerProvider.value && slots.value.length) {
        fetchProfiles();
      } else if (isResellerProvider.value) {
        if (
          tableRefs.viewConfiguration.page === initialViewConfiguration.page &&
          tableRefs.viewConfiguration.perPage ===
            initialViewConfiguration.perPage
        ) {
          fetchProviderById();
        } else {
          tableRefs.changeViewConfiguration({
            page: 1,
            perPage: initialViewConfiguration.perPage
          });
        }
      }
    },
    { deep: true }
  );

  watch(
    () => activeServerIdx.value,
    () => {
      !isResellerProvider.value && fetchProfiles();
    }
  );

  watch(
    [() => tableRefs.viewConfiguration, () => selectedYear.value],
    () => {
      !isResellerProvider.value ? fetchProfiles() : fetchProviderById();
    },
    { deep: true }
  );

  async function openQrModal(type: string, listId: number | string) {
    modalLoading.value = true;
    const typeTitle = isResellerProvider.value
      ? "-"
      : slots.value[activeTypeIdx.value]?.title || "-";
    const serverName = isResellerProvider.value
      ? "-"
      : servers.value[activeServerIdx.value]?.title || "-";
    const profile = profiles.value.find((profile: Profile) => {
      return "listId" in profile
        ? profile.listId === listId
        : profile.sku === listId;
    });
    const profileTitle = profile?.userId || "-";
    installationTitle.value = `${profileTitle}/ ${typeTitle}/ ${serverName}`;
    installationModalOpened.value = true;
    const { success, message, data } = await api.getEsimQr(type, listId);
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
    if (data) {
      const { image, iccid, address, code } = data;
      qr.address = address;
      qr.code = code;
      qr.image = image;
      qr.iccid = iccid;
    }
    await nextTick(() => {
      modalLoading.value = false;
    });
  }

  async function fetchProviderById() {
    const id = providers.value[activeProviderIdx.value]?.id;
    const view = tableRefs?.viewConfiguration || initialViewConfiguration;

    if (id) {
      const { success, message, data } = await api.fetchEsimProviderById({
        ...view,
        id
      });

      if (!success && message) {
        return await store.dispatch("showErrorNotification", message);
      }
      if (data) {
        profiles.value = data.result;
        totalProfiles.value = +data.count?.total;
        resellerCountData.value = data.count;
        resellerCountData.value.updated = data.count.updated
          ? getFullDate(new Date(data.count.updated))
          : "";
        loading.value = false;
        await store.dispatch("setAppLoading", false);
      }
    }
  }

  async function fetchSlots() {
    loading.value = true;

    const { success, message, data: types = [] } = await api.fetchEsimTypes();

    if (!success && message) {
      return await store.dispatch("showErrorNotification", message);
    }

    providers.value = types;
    if (!isResellerProvider.value) {
      slots.value = types[activeProviderIdx.value].multitel.filter(
        ({ server }) => server.length
      );
      activeTypeIdx.value = 0;
      activeServerIdx.value = 0;
    } else {
      await nextTick(() => {
        fetchProviderById();
      });
    }
  }

  async function fetchServers(id: number, year: string) {
    loading.value = true;
    await store.dispatch("setAppLoading", true);

    const { data, success, message } = await api.fetchEsimServers(id, year);

    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
      await store.dispatch("setAppLoading", false);
      loading.value = false;

      return;
    }

    servers.value = data || [];
    await nextTick(() => {
      loading.value = false;
      fetchProfiles();
    });
  }

  async function fetchProfiles() {
    const id = servers.value[activeServerIdx.value]?.serverId;
    const view = tableRefs?.viewConfiguration || initialViewConfiguration;

    if (id) {
      await store.dispatch("setAppLoading", true);

      const {
        success,
        message,
        data: profilesList = [],
        total = 0
      } = await api.fetchEsimProfiles({
        ...view,
        serverId: id,
        year: selectedYear.value
      });

      if (!success && message) {
        return await store.dispatch("showErrorNotification", message);
      }

      await store.dispatch("setAppLoading", false);
      profiles.value = profilesList;
      totalProfiles.value = total ?? 0;
    }
  }

  async function synchronizeResellerProfiles() {
    buttonLoading.value = true;
    const { success, message } = await api.synchronizeResellerProfiles();
    if (!success && message) {
      buttonLoading.value = false;
      return await store.dispatch("showErrorNotification", message);
    }
    if (success) {
      await store.dispatch("setAppLoading", true);
      await fetchProviderById();
    }
    buttonLoading.value = false;
    await store.dispatch("setAppLoading", false);
  }

  return {
    installationTitle,
    installationModalOpened,
    modalLoading,
    qr,
    importModalOpened,
    uploadLoading,
    uploadProgress,
    uploadSuccess,
    uploadErrorMessage,
    appRefs,
    loading,
    activeProviderIdx,
    providersTitles,
    activeTypeIdx,
    slotsTitles,
    activeServerIdx,
    serversTitles,
    isResellerProvider,
    activeServer,
    resellerCountData,
    profiles,
    cells,
    rows,
    totalProfiles,
    tableRefs,
    buttonLoading,
    hideImportModal,
    importFile,
    synchronizeResellerProfiles,
    changeTab
  };
}
