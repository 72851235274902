
import { defineComponent } from "vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import SelectDropdown from "../../../components/ui/Dropdown/SelectDropdown.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "../../../components/ui/Buttons/BtnContent.vue";
import AppTableController from "../../../components/Table/ts/AppTableController.vue";
import AppTabsHead from "../../../components/AppTabs/AppTabsHead.vue";
import ProfileSlotDetails from "@/components/Packages/eSIM/Profiles/ProfileSlotDetails.vue";
import DragAndDrop from "@/components/ui/DragAndDrop.vue";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppTable from "@/components/Table/ts/AppTable.vue";
import AppPreloader from "@/components/ui/AppPreloader.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import useEsimProfiles from "@/hooks/esim/profiles/useEsimProfiles";
import HeadToolBar from "@/components/Table/HeadToolBar.vue";

export default defineComponent({
  components: {
    PageMainHeading,
    SelectDropdown,
    AppButton,
    BtnContent,
    AppTableController,
    AppTabsHead,
    ProfileSlotDetails,
    DragAndDrop,
    Dialog,
    AppTable,
    AppPreloader,
    SvgIcon,HeadToolBar
  },
  setup() {
    return {
      ...useEsimProfiles()
    };
  }
});
